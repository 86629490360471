<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("ROOM") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}
      </b-button>
    </div>

    <storeModalVue @updateList="setup(true)"></storeModalVue>

    <div class="content">
      <div class="content-header">
        <searchBar
          :searchText="$t('PIECE_SEARCH')"
          :searchFunc="allpiece"
          :filtre="{
            with_default: filtre_default,
            entreprise: filtre_entreprise ? filtre_entreprise.id : null,
          }"
          :showFiltre="true"
          @returnValue="updateSearch($event)"
        ></searchBar>

        <b-modal id="entrepriseModal" ref="entrepriseModal">
          <template #modal-header="{ close }">
            <h5>{{ $t("COMPANY_ID") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>

          <form @submit.prevent="hideModal('entrepriseModal')">
            <div class="center">
              <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
                <searchInput
                  :list="getAllentreprises"
                  :loader="getentrepriseLoading"
                  label="lib"
                  :searchFunc="all_entreprises"
                  @searchfilter="selectentreprise($event)"
                ></searchInput>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  v-model="filtre_default"
                  :value="1"
                  :unchecked-value="0"
                >
                  {{ $t("Afficher les pieces publiques") }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </form>
          <template #modal-footer>
            <div class="actionModel">
              <b-button variant="success" @click="setup(true)">
                <div class="block-spinner">
                  {{ $t("Appliquer") }}
                </div>
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>
      <div class="filtre">
        <p class="table-count">{{ getTotalRowPiece }} {{ $t("ROOM") }}</p>
        <div class="productTrie" v-if="currententreprise.lib != null">
          {{ currententreprise.lib }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="selectentreprise()"
          />
        </div>

        <div class="productTrie" v-if="currentDefault == 1">
          Voir {{ $t("ROOM") }} publiques
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="
              filtre_default = null;
              setup(true);
            "
          />
        </div>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead>
            <tr>
              <th v-if="isSuperAdmin" style="border-radius: 0px 5px 0px 0px">
                {{ $t("SOCIETY") }}
              </th>
              <th>
                {{ $t("PICTURE") }}
              </th>
              <th>
                {{ $t("WORDING") }}
              </th>
              <th>
                {{ $t("CATEGORY") }}
              </th>
              <!-- <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("FAVORITE") }}
              </th> -->
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllpiece"
              :key="i"
              :class="{ default_element: data.is_default }"
            >
              <td v-if="isSuperAdmin" aria-colindex="1" role="cell">
                {{ data.entreprise.lib }}
              </td>
              <td aria-colindex="1" role="cell">
                <img
                  v-if="data.gallery"
                  class="prodimg"
                  :src="`${galleryUrl}${data.gallery.image}`"
                  alt=""
                />
                <img v-else src="@/assets/noimg.jpg" class="prodimg" alt="" />
              </td>
              <td aria-colindex="2" role="cell">
                <font-awesome-icon
                  icon="fa-solid fa-bookmark"
                  class="icons"
                  v-if="data.is_default"
                />
                {{ data.lib }}
              </td>
              <td aria-colindex="3" role="cell">
                <span v-if="data.categorie == 'type.particulier'"
                  >Particulier</span
                >
                <span v-else>Professionnel</span>
              </td>
              <!-- <td aria-colindex="4" role="cell" class="favoris">
                <span class="red" v-if="data.favoris == 1"
                  ><font-awesome-icon icon="heart" style="font-size: 18px"
                /></span>
              </td> -->
              <td
                aria-colindex="5"
                role="cell"
                class="action_col"
                v-if="!data.is_default || (data.is_default && isSuperAdmin)"
              >
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-modal
        id="ModelConfigpiece"
        ref="ModelConfigpiece"
        hide-footer
        @hide="v$.$reset()"
      >
        <template #modal-header>
          <h5>{{ $t("EDIT") }} {{ $t("ROOM") }}</h5>
          <b-button size="sm" @click="resetModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>
        <form v-if="pieceToConfig">
          <div class="center">
            <b-form-group :label="$t('WORDING')" label-for="lib">
              <b-form-input
                id="lib"
                v-model="v$.pieceToConfig.lib.$model"
                :state="validateState('lib')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="lib-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.pieceToConfig.lib.$errors"
                id="lib-feedback"
              ></error-handle>
              <div v-if="erreurlist.lib" class="error-message">
                <ul v-if="Array.isArray(erreurlist.lib)">
                  <span v-for="(e, index) in erreurlist.lib" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.lib }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('CATEGORY')" label-for="cat">
              <b-form-select
                v-model="pieceToConfig.categorie"
                :options="options"
                id="cat"
                size="sm"
              ></b-form-select>
              <div v-if="erreurlist.categorie" class="error-message">
                <ul v-if="Array.isArray(erreurlist.categorie)">
                  <span v-for="(e, index) in erreurlist.categorie" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.categorie }}</span>
              </div>
              <div
                v-if="v$.pieceToConfig.categorie.$error"
                class="error-message"
              >
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </b-form-group>

            <b-form-group :label="$t('GALLERY')">
              <multiselect
                v-model="pieceToConfig.gallery"
                :placeholder="$t('CHOOSE_IMAGE')"
                label="titre"
                track-by="titre"
                :options="getAllgallery"
                :option-height="72"
                :custom-label="customLabel"
                :show-labels="false"
                @search-change="debounceInput2"
                :loading="getLoadinggallery"
                :internal-search="false"
                :close-on-select="true"
              >
                <template slot="singleLabel" slot-scope="props">
                  <div class="option">
                    <span class="option__desc">
                      <span class="option__title">
                        {{ props.option.titre }}
                      </span>
                    </span>
                  </div>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option">
                    <img
                      class="option__image mr-4"
                      :src="`${galleryUrl}${props.option.image}`"
                      alt="None"
                    />
                    <span class="option__desc">
                      <span class="option__title">
                        {{ props.option.titre }}
                      </span>
                    </span>
                  </div>
                </template>
                <template slot="noResult"> {{ $t("NO_DATA_FOUND") }} </template>
              </multiselect>
              <div v-if="erreurlist.gallery_id" class="error-message">
                <ul v-if="Array.isArray(erreurlist.gallery_id)">
                  <span
                    v-for="(e, index) in erreurlist.gallery_id"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.gallery_id }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
              <multiselect
                v-model="pieceToConfig.entreprise"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="computedAllentreprises"
                label="lib"
                track-by="id"
                @search-change="debounceInput3"
                :loading="getentrepriseLoading"
                :internal-search="false"
              >
                <template slot="noResult"> {{ $t("NO_DATA_FOUND") }} </template>
              </multiselect>
              <div v-if="erreurlist.entreprise_id" class="error-message">
                <ul v-if="Array.isArray(erreurlist.entreprise_id)">
                  <span
                    v-for="(e, index) in erreurlist.entreprise_id"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.entreprise_id }}</span>
              </div>
            </b-form-group>
          </div>

          <div class="actionModel">
            <b-alert variant="warning" show v-if="errorU" class="messageError">
              {{ errorU }}
            </b-alert>
            <b-button variant="success" @click="pieceModalNew">
              <div class="block-spinner">
                {{ $t("EDIT") }}
                <div v-if="getLoadingpiece" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </form>
      </b-modal>

      <deleteModalVue
        :loader="getLoadingpiece"
        :elemToDelete="pieceToDelete"
        :elemDelete="delete_piece"
        @updateList="setup(false)"
      ></deleteModalVue>

      <b-pagination
        v-if="search == ''"
        v-model="page"
        :total-rows="getTotalRowPiece"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getTotalRowPiece"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getLoadingpiece"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche to="/how-it-work/piece"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import _ from "lodash";
import searchInput from "@/components/ui/searchInput";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import deleteModalVue from "../components/ui/deleteModal.vue";
import storeModalVue from "../components/Ajoutmodel/piecemodale.vue";
import searchBar from "../components/ui/searchBar.vue";
import errorHandle from "../components/ui/errorHandle.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchBar,
    searchInput,
    deleteModalVue,
    storeModalVue,
    errorHandle,
    CommentMarche,
  },
  data() {
    return {
      search: "",
      options: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
      pieceToConfig: null,
      pieceToDelete: null,
      page: 1,
      perPage: 10,
      galleryUrl: domains.gallery,
      errorD: false,
      errorS: false,
      errorU: false,
      searchE: "",
      searchBlock: false,
      currententreprise: {
        id: null,
      },
      optionDefault: [
        { value: null, text: this.$t("HIDE_PUBLIC") },
        { value: 1, text: this.$t("SHOW_PUBLIC") },
      ],
      erreurlist: {
        lib: null,
        entreprise_id: null,
        categorie: null,
        gallery_id: null,
      },
      currentDefault: null,
      filtre_default: null,
      filtre_entreprise: {
        id: null,
      },
    };
  },
  validations() {
    return {
      pieceToConfig: {
        lib: { required, maxLength: maxLength(100) },
        categorie: { required },
      },
    };
  },
  methods: {
    ...mapActions([
      "edit_piece",
      "allpiece",
      "delete_piece",
      "allgallery",
      "all_entreprises",
    ]),

    validateState(name) {
      const { $dirty, $error } = this.v$.pieceToConfig[name];
      return $dirty ? !$error : null;
    },

    customLabel({ title }) {
      return `${title}`;
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    debounceInput2: _.debounce(function (e) {
      this.allgallery({
        search: e,
      });
    }, 500),

    debounceInput3: _.debounce(function (e) {
      this.all_entreprises({
        search: e,
      });
    }, 500),

    selectentreprise(query) {
      if (query) {
        this.filtre_entreprise = query;
      } else {
        this.filtre_entreprise = {
          lib: null,
        };
      }
      this.setup(true);
      this.hideModal("entrepriseModal");
    },

    async searchPiece() {
      await this.allpiece({
        search: this.search,
        page: this.page,
        per_page: this.perPage,
        with_default: this.filtre_default,
        entreprise: this.filtre_entreprise.id,
      });
    },
    async triepiece(bool = null) {
      this.favoris = bool;
      if (bool != null) {
        await this.allpiece({
          page: this.page,
          per_page: this.perPage,
          favoris: bool,
          search: this.search,
          with_default: this.isSuperAdmin ? null : 1,
        });
      } else {
        this.searchPiece();
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    handleUpdate(CAT) {
      this.pieceToConfig = { ...CAT };
      this.$refs["ModelConfigpiece"].show();
    },
    handleDelete(CAT) {
      this.pieceToDelete = { ...CAT };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    async pieceModalNew() {
      const isFormCorrect = await this.v$.pieceToConfig.$error;
      if (isFormCorrect) return;
      this.erreurlist = {
        lib: null,
        entreprise_id: null,
        categorie: null,
        gallery_id: null,
      };
      this.errorU = null;
      const data = {
        lib: this.pieceToConfig.lib,
        id: this.pieceToConfig.id,
        gallery_id: this.pieceToConfig.gallery
          ? this.pieceToConfig.gallery.id
          : null,
        categorie: this.pieceToConfig.categorie,
        favoris: this.pieceToConfig.favoris.toString(),
        lang: "fr",
      };
      if (this.pieceToConfig.entreprise && this.pieceToConfig.entreprise.id) {
        data.entreprise_id = this.pieceToConfig.entreprise.id;
      }
      await this.edit_piece(data)
        .then(() => {
          this.hideModal("ModelConfigpiece");
          this.errorU = null;
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    resetModal() {
      this.erreurlist = {
        lib: null,
        entreprise_id: null,
        categorie: null,
        gallery_id: null,
      };
      this.errorU = null;
      this.hideModal("ModelConfigpiece");
    },
    async pieceDelete() {
      await this.delete_piece({
        id: this.pieceToDelete.id,
      })
        .then(() => {
          this.hideModal("ModelDeletepiece");
          this.errorD = null;
        })
        .catch((err) => {
          this.errorD = err;
        });
    },

    handleStore() {
      this.$root.$emit("bv::show::modal", "pieceModal");
    },

    pagination(paginate) {
      this.page = paginate;
      this.searchPiece();
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.currentDefault = this.filtre_default;
      this.currententreprise = this.filtre_entreprise;
      this.$refs["entrepriseModal"].hide();

      if (this.getOnlineUser) {
        if (this.isSuperAdmin) {
          this.allpiece({
            page: this.page,
            per_page: this.perPage,
            with_default: this.filtre_default,
            entreprise: this.filtre_entreprise.id,
          });
        } else {
          this.allpiece({
            page: this.page,
            per_page: this.perPage,
            entreprise: this.getOnlineUser.entreprise.id,
            with_default: this.filtre_default,
          });
        }
      }
    },
  },

  mounted() {
    this.setup(true);
    this.allgallery({ page: this.page, per_page: 1000 });
    if (this.isSuperAdmin)
      this.all_entreprises({
        page: this.page,
        per_page: 1000,
        search: this.search,
      });
  },

  computed: {
    ...mapGetters([
      "getAllpiece",
      "getLoadingpiece",
      "getTotalRowPiece",
      "getTabs",
      "getAllgallery",
      "getOnlineUser",
      "getAllentreprises",
      "getentrepriseLoading",
      "getLoadinggallery",
    ]),

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    computedAllentreprises() {
      var list = [
        {
          lib: "TOUTES LES ENTREPRISES",
          id: 1,
        },
      ];
      if (this.getAllentreprises)
        this.getAllentreprises.forEach((element) => {
          if (element.id != 1) list.push(element);
        });
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group .icon-balance {
  top: 8px;
}

.prodimg {
  padding: 8px;
  background: #465081;
  max-width: 64px;
  max-height: 64px;
}
.option {
  display: flex;
  align-items: center;
  .option__image {
    background-color: #465081;
    padding: 4px;
    max-height: 42px;
    max-width: 42px;
  }
}
.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.actionModel {
  position: relative;
  padding: 15px 0 0;
  flex-flow: column;
  align-items: center;
  .messageError {
    color: #e4261b;
    font-size: 16px;
    background-color: #fff;
    border-color: #fff;
  }
  & .block-spinner {
    display: flex;
    justify-content: center;
  }

  & .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.detailClient {
  background-color: #fff;
  box-shadow: 1px 1px 24px #00000019;

  .clientTop {
    padding: 16px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #707070;

    & .prodimg {
      max-width: 185px;
      max-height: 231px;
    }
  }

  & h4 {
    color: #28367a;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  & p {
    color: #515151;
    font-size: 16px;
    font-weight: 500;
  }
}
.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin-right: 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

.image-conteneur {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .block {
    cursor: pointer;
    width: 25%;
    margin-bottom: 16px;
    padding: 15px;
    &:hover {
      background-color: rgba($color: #000, $alpha: 0.1);
    }

    .image-content {
      img {
        width: 100%;
      }
    }

    p {
      margin: 0;
      text-align: center;
      color: #303030;
    }
  }
}

.galerieImage {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20%;
    max-height: 100px;
  }
}
</style>
